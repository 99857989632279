import { ReactComponent as Beer } from "../images/Beer.svg"
import { ReactComponent as Book } from "../images/Book.svg"
import { ReactComponent as Cards } from "../images/Cards.svg"
import { ReactComponent as Cart } from "../images/Cart.svg"
import { ReactComponent as CheckCircle } from "../images/CheckCircle.svg"
import { ReactComponent as Clock } from "../images/Clock.svg"
import { ReactComponent as Closed } from "../images/Closed.svg"
import { ReactComponent as Cocktail } from "../images/Cocktail.svg"
import { ReactComponent as Delivery } from "../images/Delivery.svg"
import { ReactComponent as Dining } from "../images/Dining.svg"
import { ReactComponent as ForSale } from "../images/ForSale.svg"
import { ReactComponent as Groceries } from "../images/Groceries.svg"
import { ReactComponent as Info } from "../images/Info.svg"
import { ReactComponent as Instagram } from "../images/Instagram.svg"
import { ReactComponent as LeftChevron } from "../images/LeftChevron.svg"
import { ReactComponent as Link } from "../images/Link.svg"
import { ReactComponent as MapMarker } from "../images/MapMarker.svg"
import { ReactComponent as Menu } from "../images/Menu.svg"
import { ReactComponent as Merch } from "../images/Merch.svg"
import { ReactComponent as Phone } from "../images/Phone.svg"
import { ReactComponent as Pin } from "../images/Pin.svg"
import { ReactComponent as Star } from "../images/Star.svg"
import { ReactComponent as Tag } from "../images/Tag.svg"
import { ReactComponent as Tiles } from "../images/Tiles.svg"
import { ReactComponent as Warning } from "../images/Warning.svg"
import { ReactComponent as Website } from "../images/Website.svg"
import { ReactComponent as Wine } from "../images/Wine.svg"

const Icons = {
  Beer,
  Book,
  Cards,
  Cart,
  CheckCircle,
  Clock,
  Closed,
  Cocktail,
  Delivery,
  Dining,
  ForSale,
  Groceries,
  Info,
  Instagram,
  LeftChevron,
  Link,
  MapMarker,
  Menu,
  Merch,
  Phone,
  Pin,
  Star,
  Tag,
  Tiles,
  Warning,
  Website,
  Wine,
}

export default Icons
